/**
 * This file exports selectors and actions. It uses the store configured in "global-store.ts" and actions defined in corresponding slice files
 */
import store from "./global-store";
import {
  setOrderDetails,
  setSearchOrderCriteria,
  setSearchOrderResult,
  setSelectedOrder,
  setFilterInput,
  setSortedData,
  setAdditionalAttribute,
  setsearchRadioButton,
  setSelectedSku,
  setFilteredSku,
  setSortedSku,
  resetOrderState,
  setFilteredItem,
  setcheckedItem,
  setEditedSku,
  setEditedOrder,
} from "./order-slice";
import { setItemSearch } from "./item-slice";
import { setResetStoreState,setApprovedOrderFromOrder, setOrderUpdatedForSpend, setOrderUpdatedForApproval } from "./shared-slice";
import { setSelectedSubmittedData,setFilteredSubmittedData,setSortedSubmittedData, setSubmittedOrder } from "./approval-slice";
import { setEditedSpendList, setFilteredSpendInput, setNewSpendRecord, setSelectedSpendData, setSortedSpendData, setSpendList } from "./spend-slice";
import { resetBuysheetState, setBuysheetFilterInput, setBuysheetSortInput, setBuysheetViewInput } from "./buysheet-slice";
// Order Selectors
export const selectSearchOrderResult = () =>
  store.getState().order.searchOrderResult;
export const selectSearchOrderCriteria = () =>
  store.getState().order.searchOrderCriteria;
export const selectOrderDetails = () => store.getState().order.orderDetails;
export const selectSelectedOrder = () => store.getState().order.selectedOrder;
export const selectFilterInput = () => store.getState().order.filterInput;
export const selectSortedData = () => store.getState().order.sortedData;
export const selectAttributeData = () => store.getState().order.additionalAttribute;
export const selectsearchRadioButton = () => store.getState().order.searchRadioButton;
export const selectSelectedSku = () => store.getState().order.selectedSku;
export const selectFilteredSku = () => store.getState().order.filteredSku;
export const selectSortedSku = () => store.getState().order.sortedSku;
export const selectFilteredItem = () => store.getState().order.filteredItem;
export const selectCheckedItem = () => store.getState().order.checkedItem;
export const selectEditedOrder = () => store.getState().order.editedOrder;
export const selectEditedSku = () => store.getState().order.editedSku;

//Item Selectors
export const selectSearchItem = () => store.getState().item.searchItem;
//shared Selectors
export const selectClickedFromHeader = () =>
  store.getState().shared.resetStore;
export const selectApprovedOrderFromOrder = () => store.getState().shared.approvedOrderFromOrder;
export const selectOrderUpdatedForApproval = () => store.getState().shared.orderUpdatedForApproval;
export const selectOrderUpdatedForSpend = () => store.getState().shared.orderUpdatedForSpend;
//approval selectors
export const selectSelectedSubmittedData = () => store.getState().approval.selectedSubmittedData;
export const selectFilteredSubmittedData = () => store.getState().approval.filteredSubmittedData;
export const selectSortedSubmittedData = () => store.getState().approval.sortedSubmittedData;
export const selectSubmittedOrder = () => store.getState().approval.submittedOrder;
//Spend selectors
export const selectSelectedSpendData = () => store.getState().spend.selectedSpendData;
export const selectFilteredSpendInput = () => store.getState().spend.filteredSpendInput;
export const selectSortedSpendData = () => store.getState().spend.sortedSpendData;
export const selectEditedSpendList = () => store.getState().spend.editedSpendList;
export const selectSpendList = () => store.getState().spend.spendList;
export const selectNewSpendRecord = () => store.getState().spend.isNewSpendRecord;
//Buysheet selectors
export const selectBuysheetSortInput = () => store.getState().buysheet.sortInput;
export const selectBuysheetFilterInput = () => store.getState().buysheet.filterInput;
export const selectBuysheetViewInput = () => store.getState().buysheet.viewInput;

// Order Actions
export const setSearchOrderResultAction = (data: any) => {
  store.dispatch(setSearchOrderResult(data));
};
export const setSearchOrderCriteriaAction = (data: any) => {
  store.dispatch(setSearchOrderCriteria(data));
};
export const setOrderDetailsAction = (data: any) => {
  store.dispatch(setOrderDetails(data));
};
export const setSelectedOrderAction = (data: any) => {
  store.dispatch(setSelectedOrder(data));
};
export const setFilterInputAction = (data: any) => {
  store.dispatch(setFilterInput(data));
};
export const setSortedDataAction = (data: any) => {
  store.dispatch(setSortedData(data));
};
export const setAdditionalAttributeAction = (data: any) => {
  store.dispatch(setAdditionalAttribute(data));
};
export const setSearchRadioButtonAction = (data: any) => {
  store.dispatch(setsearchRadioButton(data));
};
export const setSelectedSkuAction = (data: any) => {
  store.dispatch(setSelectedSku(data));
};
export const setFilteredSkuAction = (data: any) => {
  store.dispatch(setFilteredSku(data));
};
export const setSortedSkuAction = (data: any) => {
  store.dispatch(setSortedSku(data));
};
export const resetOrderStateAction = () => {
  store.dispatch(resetOrderState());
};
export const setFilteredItemAction = (data: any) => {
  store.dispatch(setFilteredItem(data));
};
export const setCheckedItemAction = (data: any) => {
  store.dispatch(setcheckedItem(data));
};
export const setEditedOrderAction = (data: any) => {
  store.dispatch(setEditedOrder(data));
};
export const setEditedSKuAction = (data: any) => {
  store.dispatch(setEditedSku(data));
};

// Item Actions
export const setItemSearchAction = (data: any) => {
  store.dispatch(setItemSearch(data));
};
//Shared Actions
export const setResetStoreStateAction = (data: any) => {
  store.dispatch(setResetStoreState(data));
};

export const setApprovedOrderFromOrderAction = (data: any) => {
  store.dispatch(setApprovedOrderFromOrder(data));
};

export const setOrderUpdatedForSpendAction = (data: any) => {
  store.dispatch(setOrderUpdatedForSpend(data));
};

export const setOrderUpdatedForApprovalAction = (data: any) => {
  store.dispatch(setOrderUpdatedForApproval(data));
};
//Approval Actions
export const setSelectedSubmittedDataAction = (data: any) => {
  store.dispatch(setSelectedSubmittedData(data));
};
export const setFilteredSubmittedDataAction = (data: any) => {
  store.dispatch(setFilteredSubmittedData(data));
};
export const setSortedSubmittedDataAction = (data: any) => {
  store.dispatch(setSortedSubmittedData(data));
};
export const setSubmittedOrderAction = (data: any) => {
  store.dispatch(setSubmittedOrder(data));
};
//Spend Actions
export const setSelectedSpendDataAction = (data: any) => {
  store.dispatch(setSelectedSpendData(data));
};
export const setFilteredSpendInputAction = (data: any) => {
  store.dispatch(setFilteredSpendInput(data));
};
export const setSortedSpendDataAction = (data: any) => {
  store.dispatch(setSortedSpendData(data));
};
export const setEditedSpendListAction = (data: any) => {
  store.dispatch(setEditedSpendList(data));
};
export const setSpendListAction = (data: any) => {
  store.dispatch(setSpendList(data));
};
export const setNewSpendRecordAction = (data: any) => {
  store.dispatch(setNewSpendRecord(data));
};
//Buysheet Actions
export const setBuysheetSortInputAction = (data: any) => {
  store.dispatch(setBuysheetSortInput(data));
};
export const setBuysheetFilterInputAction = (data: any) => {
  store.dispatch(setBuysheetFilterInput(data));
};
export const setBuysheetViewInputAction = (data: any) => {
  store.dispatch(setBuysheetViewInput(data));
};
export const resetBuysheetStateAction = () => {
  store.dispatch(resetBuysheetState());
};

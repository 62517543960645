/**
 * This file defines a Redux slice for managing the state related to spend.
 * It includes the initial state, action creators, and the reducer function for handling various spend-related actions. 
 * The actions provided here can be dispatched to update the spend-related state in the Redux store.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SpendState {
  selectedSpendData: any;
  filteredSpendInput: any;
  sortedSpendData: any;
  spendList: any;
  editedSpendList: any;
  isNewSpendRecord: boolean;
}

const initialState: SpendState = {
    selectedSpendData: '',
    filteredSpendInput: '',
    sortedSpendData: '',
    spendList: '',
    editedSpendList: '',
    isNewSpendRecord: false,
};

const spendSlice = createSlice({
  name: "spend",
  initialState,
  reducers: {
    setSelectedSpendData: (state, action: PayloadAction<any>) => {
      state.selectedSpendData = action.payload;
    },
    setFilteredSpendInput: (state, action: PayloadAction<any>) => {
      state.filteredSpendInput = action.payload;
    },
    setSortedSpendData: (state, action: PayloadAction<any>) => {
      state.sortedSpendData = action.payload;
    },
    setEditedSpendList: (state, action: PayloadAction<any>) => {
      state.editedSpendList = action.payload;
    },
    setSpendList: (state, action: PayloadAction<any>) => {
        state.spendList = action.payload;
    },
    setNewSpendRecord: (state, action: PayloadAction<any>) => {
      state.isNewSpendRecord = action.payload;
    },
  },
});

export const {
  setSelectedSpendData,
  setFilteredSpendInput,
  setSortedSpendData,
  setEditedSpendList,
  setSpendList,
  setNewSpendRecord
} = spendSlice.actions;

export default spendSlice.reducer;
